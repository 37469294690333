/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
import $ from 'jquery';

import 'bootstrap';
import 'bootstrap-slider';
//import 'bootstrap/js/dist/tooltip';

// console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

//require('@fortawesome/fontawesome-free/css/all.min.css');
//require('@fortawesome/fontawesome-free/js/all.js');

window.separate = separate;

setTimeout(function () {
  $('#successMessage').fadeOut('slow');
}, 6000);

// Thousand separator
jQuery(function ($) {
  $('.thousand').each(function (i, thousandInput) {
    separate(thousandInput, " ");
  });

  $('.thousand').focusin(function () {
    var price = $(this).attr('val');
    $(this).val(price);
  });

  $('.thousand').focusout(function () {
    separate($(this), " ");
  });

  $.fn.disableSelection = function () {
    return this
      .attr('unselectable', 'on')
      .css('user-select', 'none')
      .on('selectstart', false);
  };

  $('.plus-minus').disableSelection();
  $('.plus-minus span').disableSelection();

});


function separate(thousandInput, separator) {
  var value = $(thousandInput).attr('val');
  // Absolutni hodnota
  value = Math.abs(value).toString();
  var chars = value.split("").reverse()
  var withSpace = []
  for (var i = 1; i <= chars.length; i++) {
    withSpace.push(chars[i - 1])
    if (i % 3 == 0 && i != chars.length) {
      withSpace.push(separator)
    }
  }
  var val = withSpace.reverse().join("")

  return $(thousandInput).val(val + ' Kč');
}


$(document).on("input", ".thousand", function () {
  this.value = this.value.replace(/\D/g, '');
});

// Thousand separator END

// Plus minus buttons

$('body').on('click', '.plus', function () {
  var button = $(this).parent();
  var step = parseFloat(button.parent().find("input").attr('step'));
  var oldValue = button.parent().find("input").attr('val');
  var newVal = parseFloat(oldValue) + step;
  button.parent().find("input").attr('val', newVal);
  button.parent().find("input").val(newVal);
  button.parent().find("input").change();
  separate(button.parent().find("input"), " ")
});

$('body').on('click', '.minus', function () {
  var button = $(this).parent();
  var step = parseFloat(button.parent().find("input").attr('step'));
  var min = button.parent().find("input").attr('min');
  var oldValue = button.parent().find("input").attr('val');
  var newVal = parseFloat(oldValue) - step;
  if (newVal < min) {
    newVal = min;
  }
  button.parent().find("input").attr('val', newVal);
  button.parent().find("input").val(newVal);
  button.parent().find("input").change();
  separate(button.parent().find("input"), " ")
});

// Plus minus buttons END

var collapseLegalExample = document.getElementById('collapseLegalExample');

collapseLegalExample.addEventListener('show.bs.collapse', function () {
  $(this).parent().find(".fa-caret-right").addClass('fa-rotate-90');
})

collapseLegalExample.addEventListener('hide.bs.collapse', function () {
  $(this).parent().find(".fa-caret-right").removeClass('fa-rotate-90');
})